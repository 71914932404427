import React from "react";
import styled from "styled-components";

const Container = styled.div`
`;

const Carousel = styled.div`
    margin: 2rem;

    .carousel-indicators [data-bs-target] {
        width: 7px;
        height: 7px;
        padding: 0;
        border-radius: 50%;
        border: 1px solid transparent;
    }

    @media only screen and (min-width: 768px) {
        margin: 0rem;
    }
`;

const Photo = styled.img`
    display: visible;
    width: 100%;
    object-fit: contain;
    border-radius: ${(props) => props.borderRadius};
    border: ${(props) => props.borderStyle};
`;

const Button = styled.button`
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        color: #b5b5b523;
        background-color: #b5b5b523;
    }
`;

const SlideShow = ({photos, borderStyle, borderRadius}) => {


    return (
        <Container id="carouselControls" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <Carousel>
                {photos.map((photo, i) => (
                    <div className={`carousel-item ${i === 0 ? "active" : ""}`}>
                        <Photo src={photo.path} alt={photo.altText} borderStyle={borderStyle} borderRadius={borderRadius}/>
                    </div>
                ))}
                <div class="carousel-indicators">
                    {photos.map((photo, i) => (
                        <button
                            type="button"
                            data-bs-target="#carouselControls"
                            data-bs-slide-to={i}
                            className={`${i === 0 ? "active" : ""}`}
                        ></button>
                    ))}
                </div>
            </Carousel>
            <Button className="carousel-control-prev" type="button" data-bs-target="#carouselControls"data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span>
                <span class="visually-hidden">Previous</span>
            </Button>
            <Button className="carousel-control-next" type="button" data-bs-target="#carouselControls" data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
                <span class="visually-hidden">Next</span>
            </Button>
        </Container>
    );
};

export default SlideShow;
