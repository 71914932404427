import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdSkipPrevious, MdSkipNext } from "react-icons/md";


const Container = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
`;

const Carousel = styled.div`
    width: 100%;
    height: 100%;
    .carousel-indicators [data-bs-target] {
        width: 8px;
        height: 8px;
        padding: 0;
        border-radius: 50%;
        border: 1px solid transparent;

        @media screen and (min-width: 620px) {
            width: 9px;
            height: 9px;
        }
    }
`;

const PhotoBound = styled.div`
    height: 100%;
    width: 100%;
`;

const Photo = styled.img`
    display: visible;
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    border-radius: ${(props) => props.borderRadius};
    border: ${(props) => props.borderStyle};
`;

const ControlsGrid = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: 10% 70% 20%;
    z-index: 2;

    @media screen and (min-width: 620px) {
        grid-template-rows: 10% 75% 25%;
    }
`;

const CloseIcon = styled(AiFillCloseCircle)`
    grid-column: 3;
    grid-row: 1;
    width: 2rem;
    height: 2rem;
    margin: 2rem 1rem auto auto;
    color: gray;

    :hover {
        color: white;
    }

    @media screen and (min-width: 620px) {
        width: 3rem;
        height: 3rem;
        margin: 1rem 1rem auto auto;
    }
`;

const PreviousIcon = styled(MdSkipPrevious)`
    grid-column: 1;
    grid-row: 2;
    width: 2rem;
    height: 2rem;
    margin: auto auto auto 0.5rem;
    color: gray;

    :hover {
        color: white;
    }

    @media screen and (min-width: 620px) {
        width: 5rem;
        height: 5rem;
        margin: auto auto auto 1rem;
    }
`;

const NextIcon = styled(MdSkipNext)`
    grid-column: 3;
    grid-row: 2;
    width: 2rem;
    height: 2rem;
    margin: auto 0.5rem auto auto;
    color: gray;

    :hover {
        color: white;
    }

    @media screen and (min-width: 620px) {
        width: 5rem;
        height: 5rem;
        margin: auto 1rem auto auto;
    }
`;

const Information = styled.div`
    grid-column: 2;
    grid-row: 3;
    padding: 0.5rem;

    @media screen and (min-width: 620px) {
        padding: 1rem;
    }
`;

const Title = styled.label`
    background-color: rgba(0, 0, 0, 0.75);
    font-size: 0.75rem;
    text-color: white;
    padding: 0.5rem;
    border-radius: 10px;
    width: auto;
    
    @media screen and (min-width: 620px) {
        font-size: 1rem;
        padding: 1rem;
    }
`;

const SlideShow = ({ photos, borderStyle, borderRadius, onClose, initialSelection}) => {
    const [activeChild, setActiveChild] = useState(initialSelection);

    const handlePreviousSlide = useCallback(() => {
        setActiveChild((a) => (a - 1 < 0 ? photos.length - 1 : a - 1));
    }, [photos]);

    const handleNextSlide = useCallback(() => {
        setActiveChild((a) => (a + 1 > photos.length - 1 ? 0 : a + 1));
    }, [photos]);

    const changeChild = useCallback(
        (e) => {
            if (e.key === "ArrowLeft") {
                handlePreviousSlide();
            } else if (e.key === "ArrowRight") {
                handleNextSlide();
            } else if (e.key === "Escape") {
                onClose();
            }
        }, [handleNextSlide, handlePreviousSlide, onClose]
    );

    useEffect(() => {
        document.addEventListener("keydown", changeChild);

        return function cleanup() {
            document.removeEventListener("keydown", changeChild);
        };
    });

    return (
        <Container
            id="carouselControls"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
            interval={null}
        >
            <Carousel>
                {photos.map((photo, i) => (
                    <PhotoBound key={i}
                                className={`carousel-item ${i === activeChild ? "active" : ""}`}>
                        <Photo
                            src={photo.highResPath}
                            alt={photo.altText}
                            borderStyle={borderStyle}
                            borderRadius={borderRadius}
                        />
                    </PhotoBound>
                ))}
                <div className="carousel-indicators">
                    {photos.map((photo, i) => (
                        <button
                            key={i}
                            type="button"
                            data-bs-target="#carouselControls"
                            className={`${i === activeChild ? "active" : ""}`}
                        ></button>
                    ))}
                </div>
            </Carousel>
            <ControlsGrid>
                <CloseIcon onClick={onClose} />
                <PreviousIcon onClick={handlePreviousSlide} />
                <NextIcon onClick={handleNextSlide} />
                <Information>
                    {photos.map((photo, i) => (
                        (i === activeChild ? <Title key={i}>{photo.title}</Title> : "")
                    ))}
                </Information>
            </ControlsGrid>
        </Container>
    );
};

export default SlideShow;
