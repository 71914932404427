import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import StatusMessage from "../components/StatusMessage";
import GalleryContent from "../components/gallery-page/GalleryContent";
import PortfolioLinkSection from "../components/gallery-page/PortfolioLinkSection";
import PhotoCategoryMenu from "../components/gallery-page/PhotoCategoryMenu";
import PageNavigation from "../components/gallery-page/PageNavigation";

const Container = styled.div`
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    margin-top: 5rem;
`;

const PageContent = styled.div`
    width: 100%;
    height: 100%;

    margin-bottom: 2rem;

    @media only screen and (min-width: 960px) {
        margin-bottom: 5rem;
    }
`;


const GalleryPage = () => {
    const { content } = useParams();

    const photosPerPage = 50;

    const tagNone = "none";
    const tagPhotography = "photo";
    const tagDigitalArtwork = "digital-artwork"; 

    const [photos, setPhotos] = useState(null);
    const [isLoadingComplete, setIsLoadingComplete] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const [searchTag, setSearchTag] = useState(tagNone);
    const [isPhotography, setIsPhotography] = useState(false);
    const [contentDescription, setContentDescription] = useState("");
    const [portfolioSite, setPortfolioSite] = useState("");
    const [portfolioUrl, setPortfolioUrl] = useState("");

    const handlePhotoCategorySelection = ({ category }) => {
        if (category.length > 0) {
            setSearchTag(`${category}`);
        } else {
            setSearchTag("photo");
        }
        setCurrentPageNumber(1);
    };

    const handleLoadingComplete = () => {
        setIsLoadingComplete(true);
    };

    const handlePageSelection = ({ pageNumber }) => {
        setCurrentPageNumber(pageNumber);
    };

    useEffect(() => {
        setPhotos(null);
        setIsLoadingComplete(false);
        setCurrentPageNumber(1);
        setSearchTag(tagNone);

        switch (content) {
            case "digitalartwork":
                setIsPhotography(false);
                setSearchTag(tagDigitalArtwork);
                setContentDescription("digital artwork");
                setPortfolioSite("Procreate Folio");
                setPortfolioUrl("https://folio.procreate.art/odell");
                break;
            case "photography":
                setIsPhotography(true);
                setSearchTag(tagPhotography);
                setContentDescription("photography");
                setPortfolioSite("Fine Art America");
                setPortfolioUrl("https://fineartamerica.com/profiles/odell-garrison");
                break;
            default:
                break;
        };
    },[content]);

    useEffect(() => {
        if (searchTag !== tagNone) {
            setPhotos(null);
            setIsLoadingComplete(false);

            //Note: Ensure that we scroll up to the top of the page if a reload is being performed.
            window.scrollTo({ top: 0, behavior: "smooth" });

            const fetchData = async () => {
                let url = `https://www.flickr.com/services/rest/?method=flickr.photos.search&api_key=17b10862777f4c22c83e2318a7cd2de3&user_id=7317392%40N02&tags=${searchTag}&tag_mode=all&page=${currentPageNumber}&per_page=${photosPerPage}&format=json&nojsoncallback=1`;
                console.log(`url: ${url}`);

                let response = await fetch(url);
                let searchResponseJson = await response.json();
                console.log(`searchResponseJson:`, searchResponseJson);

                // eslint-disable-next-line array-callback-return
                searchResponseJson.photos.photo.map(async (photo) => {
                    photo.lowResPath = `https://farm${photo.farm}.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}.jpg`;

                    url = `https://www.flickr.com/services/rest/?method=flickr.photos.getSizes&api_key=17b10862777f4c22c83e2318a7cd2de3&photo_id=${photo.id}&format=json&nojsoncallback=1`;
                    // console.log(`url: ${url}`);

                    response = await fetch(url);
                    let getSizesResponseJson = await response.json();
                    // console.log(`getSizesResponseJson:`, getSizesResponseJson);

                    let size = getSizesResponseJson.sizes.size[getSizesResponseJson.sizes.size.length - 1];
                    photo.highResPath = size.source;
                });

                console.log(`searchResponseJson.photos:`, searchResponseJson.photos);
                setPhotos(searchResponseJson.photos);
            };

            fetchData().catch(console.error);            
        }
    }, [searchTag, currentPageNumber, photosPerPage]);

    return (
        <Container>
            {photos ? (
                <PageContent>
                    <PortfolioLinkSection siteName={portfolioSite} siteUrl={portfolioUrl} />
                    {isPhotography && (
                        <PhotoCategoryMenu
                            searchTag={searchTag}
                            onSelection={handlePhotoCategorySelection} />
                    )}
                    {isLoadingComplete && (
                        <PageNavigation
                            currentPage={photos.page}
                            totalPages={photos.pages}
                            onPageSelection={handlePageSelection} />
                    )}
                    <GalleryContent
                        photos={photos.photo}
                        contentDescription={contentDescription}
                        onLoadingComplete={handleLoadingComplete} />
                    {isLoadingComplete && (
                        <>
                            <hr/>
                            <PageNavigation
                                currentPage={photos.page}
                                totalPages={photos.pages}
                                onPageSelection={handlePageSelection} />
                        </>
                    )}
                </PageContent>
            ) : (
                <StatusMessage message={`Gathering ${contentDescription}... please wait.`} />
            )}
        </Container>
    );
};

export default GalleryPage;
