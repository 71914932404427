import React from "react";
import styled from "styled-components";
import SlideShow from "../components/home-page/SlideShow";
import "./HomePage.css";

const ScreenBounds = styled.div`
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;

//    border: 1px dashed white;

    @media only screen and (min-width: 450px){
        height: 100vh;
    }
`;

const Content = styled.div`
    width: 100%;
    animation: page-content-load var(--page-content-load-time) ease-in-out;
    animation-fill-mode: forwards;
    transform: scale(0);

    //    border: 1px dashed blue;

    @keyframes page-content-load {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @media only screen and (orientation: portrait) {
        margin: 5rem 1rem 0.5rem 1rem;

        @media only screen and (min-width: 375px) {
            width: 95%;
        }
        @media only screen and (min-width: 768px) {
            width: 55%;
            margin-top: 4rem;
        }
    }

    @media only screen and (orientation: landscape) {
        // Note: Sizes smaller than 768px should be treated same as portrait.
        margin: 5rem 1rem 0.5rem 1rem;

        @media only screen and (min-width: 375px) {
            width: 95%;
        }

        // Note: From this point orientation should be treated as landscape.
        @media only screen and (min-width: 768px) {
            margin: 0rem 1rem 0.5rem 1rem;
            display: flex;
            flex: 2;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
`;

const SlideShowContainer = styled.div`
//    border: 1px dotted yellow;

    @media only screen and (orientation: landscape) {
        @media only screen and (min-width: 1024px){
            width: 40vh;
        }
    }
`;

const DescriptionContainer = styled.div`
    font-weight: 200;

//    border: 1px dotted green;

    line-height: 1rem;
    margin-top: 1.5rem;

    @media only screen and (min-width: 768px) {
        line-height: 2rem;
        margin-top: 2.5rem;
    }

    @media only screen and (orientation: landscape) {
        @media only screen and (min-width: 768px) {
            margin-left: 4rem;
            line-height: 2rem;
            margin-top: 2.5rem;
        }
        @media only screen and (min-width: 1112px) {
            line-height: 3rem;
        }
    }
`;

const Paragraph = styled.p`
    text-align: center;
    filter: drop-shadow(5px 5px 5px var(--dark-shadow-color));

    @media only screen and (orientation: portrait) {
        font-size: 1rem;

        @media only screen and (min-width: 375px) {
            font-size: 1.2rem;
        }
        @media only screen and (min-width: 414px) {
            font-size: 1.4rem;
        }
        @media only screen and (min-width: 768px) {
            font-size: 1.7rem;
        }
        @media only screen and (min-width: 834px) {
            font-size: 1.8rem;
        }
        @media only screen and (min-width: 1024px) {
            font-size: 2rem;
        }
        @media only screen and (min-width: 1200px) {
            font-size: 3.4rem;
        }
        @media only screen and (min-width: 2500px) {
            font-size: 3.5rem;
        }
    }

    @media only screen and (orientation: landscape) {
        @media only screen and (min-width: 1024px) {
            font-size: 2rem;
        }
        @media only screen and (min-width: 1112px) {
            font-size: 2rem;
        }
        @media only screen and (min-width: 1200px) {
            font-size: 2.1rem;
        }
    }
`;

const Italicized = styled(Paragraph)`
    font-style: italic;

    @media only screen and (orientation: portrait) {
        font-size: 1.3rem;

        @media only screen and (min-width: 375px) {
            font-size: 1.5rem;
        }
        @media only screen and (min-width: 414px) {
            font-size: 1.7rem;
        }
        @media only screen and (min-width: 768px) {
            font-size: 2rem;
        }
        @media only screen and (min-width: 834px) {
            font-size: 2.1rem;
        }
        @media only screen and (min-width: 1024px) {
            font-size: 2.7rem;
        }
        @media only screen and (min-width: 1200px) {
            font-size: 2.8rem;
        }
    }

    @media only screen and (orientation: landscape) {
        @media only screen and (min-width: 1024px) {
            font-size: 2.7rem;
        }
        @media only screen and (min-width: 1112px) {
            font-size: 2.7rem;
        }
        @media only screen and (min-width: 1200px) {
            font-size: 2.8rem;
        }
    }
`;

const HomePage = () => {

    let photos = [{
        path: "./assets/images/slides/portrait.jpg",
        altText: "Portrait"
    },{
        path: "./assets/images/slides/code.jpg",
        altText: "Code"
    },{
        path: "./assets/images/slides/batman.jpg",
        altText: "Batmand Digital Art"
    },{
        path: "./assets/images/slides/wonder-woman.jpg",
        altText: "Wonder Woman Digital Art"
    },{
        path: "./assets/images/slides/hunt-for-red-october.jpg",
        altText: "Hunt For Red October Digital Art"
    },{
        path: "./assets/images/slides/lone-rose.jpg",
        altText: "Rose"
    },{
        path: "./assets/images/slides/flamingo.jpg",
        altText: "Flamingo"
    },{
        path: "./assets/images/slides/iris.jpg",
        altText: "Iris"
    }];
    
    //Note: Ensure that we scroll up to the top of the page if a reload is being performed.
    window.scrollTo({ top: 0, behavior: "smooth" });

    return (
        <ScreenBounds>
            <Content>
                <SlideShowContainer className="row">
                    <SlideShow photos={photos} borderRadius="20px" borderStyle="5px solid var(--dark-font-color)"/>
                </SlideShowContainer>
                <DescriptionContainer>
                    <Italicized>Software Developer & Engineer</Italicized>
                    <Paragraph>and</Paragraph>
                    <Italicized>Amateur Artist & Photographer</Italicized>
                </DescriptionContainer>
            </Content>
        </ScreenBounds>
    );
};

export default HomePage;
