import React from "react";
import styled from "styled-components";

const Block = styled.p`
    font-family: "Bilbo", cursive;
    text-align: center;

    letter-spacing: 0.1rem;
    width: 80%;
    margin: 0 auto;
    padding: 10px;

    @media screen and (min-width: 960px) {
        width: 70%;
    }
`;

const QuotedText = styled.span`
    font-weight: 600;

    font-size: 1.4rem;
`;

const AttributedTo = styled.span`
    color: var(--ultradark-font-color);
    font-weight: 900;

    font-size: 1rem;
`;


const Quote = ({ quote, attributedTo }) => (
    <Block>
        <QuotedText>&quot;{quote}&quot;</QuotedText>
        <AttributedTo> ― {attributedTo}</AttributedTo>
    </Block>
);

export default Quote;