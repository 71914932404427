import React from "react";
import styled from "styled-components";
import { HiArrowCircleLeft } from "react-icons/hi";
import { HiArrowCircleRight } from "react-icons/hi";


const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: 0.75rem;

    @media only screen and (min-width: 620px) {
        font-size: 1rem;
    }
`;

const PageNumberContainer = styled.div`
    display: flex;
`;

const Link = styled.div`
    cursor: pointer;

    margin: 10px;

    :hover {
        color: var(--light-font-color);
    }
`;

const Page = styled(Link)`
`;

const CurrentPage = styled(Page)`
    font-weight: bold;
    color: var(--light-font-color);
`;

const PreviousPage = styled(HiArrowCircleLeft)`
    width: 1rem;
    height: 1rem;

    @media screen and (min-width: 620px) {
        width: 1.5rem;
        height: 1.5rem;
    }
`;

const NextPage = styled(HiArrowCircleRight)`
    width: 1rem;
    height: 1rem;

    @media screen and (min-width: 620px) {
        width: 1.5rem;
        height: 1.5rem;
    }
`;


const PageNavigation = ({ currentPage, totalPages, onPageSelection }) => {

    const handleDirectionClick = (direction) => {
        var pageNumber = 0;

        switch (direction) {
            case "previous":
                pageNumber = currentPage - 1
                break;
            case "next":
                pageNumber = currentPage + 1;
                break;
            default:
                break;
        };

        console.log(`pageNumber: ${pageNumber}`);
        onPageSelection({ pageNumber: pageNumber });
    };

    const handlePageClick = (pageNumber) => {
        console.log(`pageNumber: ${pageNumber}`);
        onPageSelection({ pageNumber: pageNumber });
    };


    let pageNumbers = [];

    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
        if (pageNumber === currentPage) {
            pageNumbers.push(
                <CurrentPage key={pageNumber}>
                    {pageNumber}
                </CurrentPage>
            );                               
        } else {
            pageNumbers.push(
                <Page key={pageNumber} onClick={() => handlePageClick(pageNumber)}>
                    {pageNumber}
                </Page>
            );                
        }
    }

    return (
        <Container>
            {totalPages > 1 && (
                <>
                    {currentPage > 1 && (
                        <Link>
                            <PreviousPage onClick={() => handleDirectionClick("previous")}/>
                        </Link>
                    )}
                    <PageNumberContainer>{pageNumbers}</PageNumberContainer>
                    {totalPages > 1 && currentPage < totalPages && (
                        <Link>
                            <NextPage onClick={() => handleDirectionClick("next")}/>
                        </Link>
                    )}
                </>
            )}
        </Container>
    );
}

export default PageNavigation