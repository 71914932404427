import styled from "styled-components";

const Section = styled.div`
    opacity: 0;
    animation-name: section-load;
    animation-duration: var(--section-transition-time);
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;

    padding-top: 2rem;
    margin: 5rem auto 0 auto;

    :nth-of-type(1) {
        margin-top: 0rem;
    }

    @media screen and (min-width: 960px) {
        width: 80%;
    }
`;

export default Section;