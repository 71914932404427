import React from "react";
import styled from "styled-components";

const InstitutionName = styled.h4`
    color: var(--light-font-color);
    font-weight: 700;

    font-size: 1rem;
`;

const AlsoKnownAs = styled.span`
    font-style: italic;

    font-size: 0.9em;
`;


const TermInformation = ({ institution, aka, beginTerm, endTerm }) => (
    <>
        <InstitutionName>{institution}</InstitutionName>
        {aka && (
            <AlsoKnownAs>
                (Formerly {aka})<br />
            </AlsoKnownAs>
        )}
        <span>
            {beginTerm} ― {endTerm}
        </span>
    </>
); 

export default TermInformation;