import React from "react";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
`;

const PortfolioLink = styled.a`
    text-decoration: none;
    color: var(--mid-font-color);

    font-size: 1.1rem;

    :hover {
        color: var(--light-font-color);
    }
`;

const PortfolioLinkSection = ({ siteName, siteUrl }) => (
    <Container>
        View my portfolio at <i><PortfolioLink href={siteUrl} target="_blank" rel="noreferrer">{siteName}</PortfolioLink></i>.
        <hr />
    </Container>
);

export default PortfolioLinkSection;
