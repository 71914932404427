import React from "react";
import styled from "styled-components";
import ProfileSection from "./resumé-page-sections/ProfileSection";
import SkillsSection from "./resumé-page-sections/SkillsSection";
import ExperienceSection from "./resumé-page-sections/ExperienceSection";
import ContactSection from "./resumé-page-sections/ContactSection";
import SectionNavigation from "./resumé-page-sections/SectionNavigation";

import "./ResuméPage.css";

const Container = styled.div`
    margin-bottom: 2rem;
    width: 95%;

    @media only screen and (min-width: 620px) {
        width: 80%;
        padding-bottom: 5rem;
    }
    @media only screen and (min-width: 1400px) {
        width: 1200px;
    }
`;

const ResuméPage = () => {
    
    //Note: Ensure that we scroll up to the top of the page if a reload is being performed.
    window.scrollTo({ top: 0, behavior: "smooth" });

    return (
        <>
            <Container className="mx-auto">
                <ProfileSection />
                <SkillsSection />
                <ExperienceSection />
                <ContactSection />
            </Container>
            <SectionNavigation />
        </>
    );
}; 

export default ResuméPage;
