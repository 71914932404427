import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Masonry from "../Masonry";
import SlideShow from "./SlideShow";

const ModalBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    background: rgba(0, 0, 0, 0.95);
`;


const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.95);
`;

const ModalContent = styled.div`
    display: inline-block;
    transform: translateY(9%);
    width: 100%;
    height: 80%;

    @media screen and (min-width: 620px) {
        transform: translateY(7%);
        height: 92%;
    }
`;

const GalleryContent = ({ photos, contentDescription, onLoadingComplete }) => {
    const [isShowingModalSlideshow, setIsShowingModalSlideshow] = useState(false);
    const [selectedImage, setSelectedImage] = useState(0);
    const [windowSize, setWindowSize] = useState({
         width: undefined,
         height: undefined,
    });
    const [columnCount, setColumnCount] = useState(1);
    const [gapSize, setGapSize] = useState(24);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    };

    const handleScroll = () => {
        document.body.style.overflow = "visible";
    };

    const handleToggleModalSlideshow = () => {
        scrollToTop();
        setIsShowingModalSlideshow(!isShowingModalSlideshow);
        document.body.style.overflow = (isShowingModalSlideshow ? "visible" : "hidden");
    };

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        var columnCount = 1;
        
        if (window.innerWidth >= 620) {
            columnCount = 2;
        }
        if (window.innerWidth >= 960) {
            columnCount = 4;
        }
        if (window.innerWidth >= 1200) {
            columnCount = 5;
        }
        if (window.innerWidth >= 1920) {
            columnCount = 6;
        }

        setColumnCount(columnCount);
        setGapSize(24);

    }, [windowSize.height, windowSize.width]);

    return (
        <>
            <Masonry
                photos={photos}
                columns={columnCount}
                gap={gapSize}
                contentDescription={contentDescription}
                onLoadingComplete={onLoadingComplete}
                onShowModalSlideshow={handleToggleModalSlideshow}
                selectedImage={setSelectedImage}
            />
            {isShowingModalSlideshow && (
                <>
                    <ModalBackground />
                    <ModalContainer onScroll={handleScroll}>
                        <ModalContent onScroll={handleScroll}>
                            <SlideShow
                                photos={photos}
                                initialSelection={selectedImage}
                                onClose={handleToggleModalSlideshow}
                            />
                        </ModalContent>
                    </ModalContainer>
                </>
            )}
        </>
    );
};

export default GalleryContent;
