import React from "react";
import styled from "styled-components";
import "./PhotoCategoryMenu.css";


const Menu = styled.div`
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    font-size: 0.75rem;
    margin: 1.5rem 0;

    @media screen and (min-width: 620px) {
        font-size: 1rem;
    }
`;

const MenuItem = styled.div`
    font-family: "Dosis", sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    :hover {
        color: var(--light-font-color);
    }
`;

const PhotoCategoryMenu = ({ searchTag, onSelection }) => {
    const tagAll = "photo";
    const tagAvian = "avian";
    const tagFauna = "fauna";
    const tagFlora = "flora";
    const tagLandscape = "landscape";
    const tagMovingWater = "movingwater";
    const tagStill = "still";

    const handleCategoryClick = (searchTag) => {
        onSelection({ category: searchTag });
    };

    return (
        <>
            <Menu>
                <MenuItem className={searchTag === tagAll ? "selected" : ""} onClick={() => handleCategoryClick(tagAll)}>
                    All
                </MenuItem>
                <MenuItem className={searchTag === tagAvian ? "selected" : ""} onClick={() => handleCategoryClick(tagAvian)}>
                    Avian
                </MenuItem>
                <MenuItem className={searchTag === tagFauna ? "selected" : ""} onClick={() => handleCategoryClick(tagFauna)}>
                    Fauna
                </MenuItem>
                <MenuItem className={searchTag === tagFlora ? "selected" : ""} onClick={() => handleCategoryClick(tagFlora)}>
                    Flora
                </MenuItem>
                <MenuItem className={searchTag === tagLandscape ? "selected" : ""} onClick={() => handleCategoryClick(tagLandscape)}>
                    Landscapes
                </MenuItem>
                <MenuItem className={searchTag === tagMovingWater ? "selected" : ""} onClick={() => handleCategoryClick(tagMovingWater)}>
                    Moving Water
                </MenuItem>
                <MenuItem className={searchTag === tagStill ? "selected" : ""} onClick={() => handleCategoryClick(tagStill)}>
                    Stills
                </MenuItem>
            </Menu>
            <hr />
        </>
    );
};

export default PhotoCategoryMenu;